<script>
import { RouterLink } from 'vue-router';

export default {
  components: {
    RouterLink,
  }
}
</script>

<template>
  <div class="mobile-menu visibility_type_mobile-only">
    <div class="mobile-menu__body">
      <ul class="mobile-menu__list">
        <li class="mobile-menu__item mobile-menu__item_size_m">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog" class="mobile-menu__link">Каталог</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog/category/arki" class="mobile-menu__link">Арки</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog/category/porogi" class="mobile-menu__link">Пороги</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog/category/usiliteli-porogov" class="mobile-menu__link">Усилители порогов</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog/category/remkomplekti-dnisha" class="mobile-menu__link">Ремкомплекты днища</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/catalog/category/remkomplekti-dverey" class="mobile-menu__link">Ремкомплекты дверей</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_l">
          <!-- TODO: проверить ссылку -->
          <a href="tel:+375291788811" class="mobile-menu__link">+375 29 178 88 11</a>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/wholesale" class="mobile-menu__link">Опт</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/return" class="mobile-menu__link">Условия возврата</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/delivery" class="mobile-menu__link">Доставка</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/payment" class="mobile-menu__link">Оплата</RouterLink>
        </li>
        <li class="mobile-menu__item mobile-menu__item_size_s">
          <!-- TODO: проверить ссылку -->
          <RouterLink to="/checkout" class="mobile-menu__link">Корзина</RouterLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-menu {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // TODO: надо проверить этот размер на мобилке; возможно, высота будет выходить за пределы вьюпорта
  height: calc(100vh - 100%);
  padding: 32px;
  background: #fff;
  box-shadow: 0 2px 70px 88px rgba(#fff, 0.95);
  border-radius: 12px;
}

.mobile-menu__body {
  overflow: auto;
}

.mobile-menu__list {
  text-align: center;
  text-transform: lowercase;
}

.mobile-menu__item {
  font-weight: 800;

  & + & {
    margin-top: 1em;
  }
}

.mobile-menu__item_size_s {
  font-size: 15px;
  color: #A0A0A0;

  &:hover {
    color: #000;
  }
}

.mobile-menu__item_size_m {
  font-size: 24px;
  color: #000;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 3px;
  }
}

.mobile-menu__item_size_l {
  font-size: 28px;
  color: #000;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 4px;
  }
}

.mobile-menu__link {
  text-decoration: none;
  font: inherit;
  color: inherit;
}
</style>
