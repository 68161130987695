<script>
import Button from '../Button/Button.vue';
import SearchInput from '../SearchInput/SearchInput.vue';
import Section from '../Section/Section.vue';

export default {
  emits: ['search-input'],

  props: {
    mix: String,
    searchResults: [Array, null],
  },

  components: {
    Button,
    SearchInput,
    Section,
  },

  data() {
    return {
    }
  },

  methods: {
    handleSearchInput(query) {
      this.$emit('search-input', query);
    }
  }
}
</script>

<template>
  <Section :mix="`${mix || ''} lead-section`">
    <div class="lead-section__block">
      <div class="lead-section__bg-gradient lead-section__bg-gradient_type_1"></div>
      <div class="lead-section__bg-gradient lead-section__bg-gradient_type_2"></div>
      <div class="lead-section__bg-gradient lead-section__bg-gradient_type_3"></div>

      <div class="lead-section__content">
        <h1 class="lead-section__headline">Производитель порогов и арок №1&nbsp;в&nbsp;Беларуси</h1>

        <div class="lead-section__actions">
          <Button
            mix="lead-section__action"
            as="a"
            mod-type="secondary"
          >Перейти в каталог</Button>
          <Button
            mix="lead-section__action"
            as="a"
            mod-type="secondary"
            href="#feedback"
          >Заказать</Button>
        </div>
      </div>
    </div>

    <img class="lead-section__img visibility_type_mobile-only" src="./assets/bg@mobile.png" alt="">
    <img class="lead-section__img visibility_type_tablet-only" src="./assets/bg@tablet.png" alt="">
    <img class="lead-section__img visibility_type_desktop-only" src="./assets/bg@desktop.png" alt="">
    <img class="lead-section__img visibility_type_desktop-wide-only" src="./assets/bg@desktop-wide.png" alt="">

    <div class="lead-section__search">
      <SearchInput
        :results="searchResults"
        @input="handleSearchInput"
      />
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.lead-section__block {
  overflow: hidden;
  //                 ↓ ← этот calc динамически вычисляет отступ снизу для автоматического «ресайза» картинки на фоне
  //                 ↓   логика такая, что на 375px у нас отступ 400px, а дальше каждые 1.2 пикселя ширины добавляют 1px в этот отступ
  //                 ↓   для остальных брейкпоинтов аналогично, только коэфф. другие
  padding: 16px 30px calc((100vw - 375px) / 1.2 + 400px);
  border-radius: 10px;
  background: #F7F8FA;
  box-shadow: 0px 0px 7.58098px -2.52699px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    border-radius: 24px;
    padding: 36px 36px calc((100vw - 768px) / 1.85 + 250px);
  }

  @media (min-width: 1024px) {
    border-radius: 32px;
    padding: 48px 48px calc((100vw - 1024px) / 1.9 + 340px);
  }

  @media (min-width: 1280px) {
    border-radius: 40px;
    padding: 32px 32px 460px;
  }
}

.lead-section__content {
  max-width: 250px;
  margin: 0 auto;

  @media (min-width: 768px) {
    // вычисляем размер блока для текста, чтобы он тянулся вместе с размером шрифта
    max-width: calc(324px + (100vw - 768px) / 1.4);
  }

  @media (min-width: 1024px) {
    max-width: calc(424px + (100vw - 1024px) / 1.4);
  }

  @media (min-width: 1280px) {
    max-width: 600px;
  }
}

.lead-section__bg-gradient {
  pointer-events: none;
  position: absolute;
}

.lead-section__bg-gradient_type_1 {
  width: 230px;
  height: 201px;
  left: 50%;
  top: 180px;
  background: linear-gradient(136.03deg, #979CA3 0%, rgba(255, 255, 255, 0) 36.46%);
  opacity: 0.5;
  filter: blur(30.0499px);
  transform: translateX(-50%) matrix(0.64, 0.77, -0.77, 0.64, 0, 0);

  @media (min-width: 768px) {
    width: 522px;
    height: 455px;
    left: 50%;
    top: 90%;
    filter: blur(67.9511px);
  }

  @media (min-width: 1024px) {
    width: 695px;
    height: 606px;
    filter: blur(90.5338px);
  }

  @media (min-width: 1280px) {
    width: 960px;
    height: 837px;
    filter: blur(125px);
  }
}

.lead-section__bg-gradient_type_2 {
  width: 105px;
  height: 113px;
  left: 5px;
  top: -29px;
  background: linear-gradient(123.04deg, #979CA3 13.58%, rgba(255, 255, 255, 0) 65.9%);
  opacity: 0.5;
  filter: blur(30.0499px);
  transform: matrix(0.97, 0.22, -0.23, 0.97, 0, 0);

  @media (min-width: 768px) {
    width: 237px;
    height: 255px;
    left: 13px;
    top: -65px;
    filter: blur(67.9511px);
  }

  @media (min-width: 1024px) {
    width: 316px;
    height: 340px;
    left: 17px;
    top: -87px;
    filter: blur(90.5338px);
  }

  @media (min-width: 1280px) {
    width: 436px;
    height: 470px;
    left: 24px;
    top: -120px;
    filter: blur(125px);
  }
}

.lead-section__bg-gradient_type_3 {
  width: 137px;
  height: 141px;
  right: 6px;
  top: -22px;
  background: linear-gradient(123.04deg, #979CA3 13.58%, rgba(255, 255, 255, 0) 65.9%);
  opacity: 0.5;
  filter: blur(30.0499px);
  transform: matrix(-0.42, 0.91, -0.91, -0.42, 0, 0);

  @media (min-width: 768px) {
    width: 310px;
    height: 319px;
    right: 6px;
    top: -38px;
    filter: blur(67.9511px);
  }

  @media (min-width: 1024px) {
    width: 413px;
    height: 425px;
    right: 10px;
    top: -64px;
    filter: blur(90.5338px);
  }

  @media (min-width: 1280px) {
    width: 570px;
    height: 587px;
    right: -30px;
    top: -60px;
    opacity: 0.8;
    filter: blur(125px);
  }
}

.lead-section__headline {
  // padding и margin для того, чтобы скоменсировать выступающие элементы текста, которые иначе обрезаются
  // из-за background-clip
  padding: 16px;
  margin: -16px;
  line-height: 1;
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  background: radial-gradient(122.52% 294.23% at -18.63% 50%, #000000 24.31%, rgba(0, 0, 0, 0.61) 51.91%, #000000 81.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 768px) {
    // вычисляем размер шрифта, чтобы текст лучше тянулся
    font-size: calc(38px + (100vw - 768px) / 12);
    text-align: justify;
  }

  @media (min-width: 1024px) {
    font-size: calc(50px + (100vw - 1024px) / 12);
  }

  @media (min-width: 1280px) {
    font-size: 68px;
    text-align: left;
  }
}

.lead-section__img {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: calc(100% - 32px);
  /*
    как это работает:
    нам нужно чтобы картинка стабильно выходила за пределы блока на один и тот же процент _самой картинки_
    но мы не можем указать ширину картинке в процентах, т. к. эти проценты считаются от блока, а не от картинки
    потому мы сперва укладываем картинку строго в границы блока (100% - padding блока)
    а затем масштабируем картинку на нужный процент:
    1.103... — это 680 / 616, где 680 — полная ширина картинки, 616 — ширина невыступающей её части
    а 10.38961039% / 2 — это компенсация этого увеличения, чтобы картинка не съезжала вниз
   */
  transform: translateX(-50%) translateY(calc(-10.38961039% / 2)) scale(1.1038961039);
  max-width: none;

  @media (min-width: 768px) {
    top: 0;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }
}

.lead-section__actions {
  display: flex;
  gap: 16px;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  margin-top: 12px;

  @media (min-width: 768px) {
    top: 53%;
    width: 324px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    gap: 24px;
    width: 424px;
  }

  @media (min-width: 1280px) {
    gap: 32px;
    top: 55%;
    width: 600px;
  }
}

.lead-section__action {
  white-space: nowrap;
  flex-grow: 1;

  @media (min-width: 768px) {
    flex-basis: 50%;
  }
}

.lead-section__search {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 160px;
  transform: translateX(-50%);
  width: 250px;

  @media (min-width: 768px) {
    top: calc(53% + 36px + 20px); // отступ кнопок + их высота + отступ от них
    width: 324px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    width: 424px;
  }

  @media (min-width: 1280px) {
    width: 600px;
    margin-top: 36px;
  }
}
</style>
