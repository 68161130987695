<script>
import { RouterLink } from 'vue-router';
import CartButton from '../CartButton/CartButton';
import IconButton from '../IconButton/IconButton';

export default {
  props: {
    isMobileMenuOpen: {
      type: Boolean,
      required: true,
    },
    onMobileMenuToggle: {
      type: Function,
      required: true,
    },
  },
  methods: {
    RouterLink() {
      return RouterLink
    }
  },
  components: {
    CartButton,
    IconButton,
    RouterLink,
  }
}
</script>

<template>
  <ul class="top-menu">
    <li class="top-menu__item visibility_type_flex-no-mobile">
      <!-- TODO: проверить ссылку -->
      <RouterLink to="/catalog" class="top-menu__link">Каталог</RouterLink>
    </li>
    <li class="top-menu__item visibility_type_flex-no-mobile">
      <a href="tel:+375291788811" class="top-menu__link top-menu__phone">+375 29 178 88 11</a>
    </li>
    <li class="top-menu__item visibility_type_mobile-only">
      <IconButton
        as="a"
        mod-size="l"
        mod-theme="dark"
        mod-icon="phone"
        href="tel:+375291788811"
        text="Позвонить нам"
      />
    </li>
    <li class="top-menu__item">
      <CartButton
        :amount="120"
      />
    </li>
    <li class="top-menu__item visibility_type_mobile-only">
      <IconButton
        as="button"
        mod-size="l"
        mod-theme="dark"
        :mod-icon="isMobileMenuOpen ? 'cross' : 'hamburger'"
        :text="isMobileMenuOpen ? 'Закрыть меню' : 'Раскрыть меню'"
        @click="onMobileMenuToggle"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.top-menu {
  display: flex;
  gap: 4px;

  @media (min-width: 768px) {
    gap: 20px;
  }

  @media (min-width: 1024px) {
    gap: 32px;
  }

  @media (min-width: 1280px) {
    gap: 40px;
  }
}

.top-menu__item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-transform: lowercase;

  @media (min-width: 1024px) {
    font-size: 20px;
  }

  @media (min-width: 1280px) {
    font-size: 26px;
  }
}

.top-menu__link {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;

    @media (min-width: 1024px) {
      text-underline-offset: 3px;
      text-decoration-thickness: 3px;
    }

    @media (min-width: 1280px) {
      text-underline-offset: 4px;
      text-decoration-thickness: 4px;
    }
  }
}

.top-menu__phone {
  font-size: 19px;

  @media (min-width: 1024px) {
    font-size: 24px;
  }

  @media (min-width: 1280px) {
    font-size: 36px;
  }

  &:hover {
    text-decoration-thickness: 3px;

    @media (min-width: 1024px) {
      text-decoration-thickness: 4px;
    }
  }
}
</style>
