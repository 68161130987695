<script>
import IconButton from '../IconButton/IconButton';

export default {
  components: { IconButton },
  props: {
    amount: Number,
  },
}
</script>

<template>
  <div class="cart-button">
    <IconButton
      as="RouterLink"
      mix="cart-button__button"
      mod-size="l"
      mod-theme="dark"
      mod-icon="cart-right"
      to="/checkout"
      text="В корзину"
    />

    <div class="cart-button__amount">{{ amount }}</div>
  </div>
</template>

<style scoped lang="scss">
.cart-button {
  position: relative;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.cart-button__button {
  // убираем hover у кнопки, чтобы переопределить его на весь компонент
  // иначе текст не будет увеличиваться
  &:hover {
    transform: none;
  }
}

.cart-button__amount {
  position: absolute;
  // позиционирование примерное, т. к. в дизайне число нечётко стоит над иконкой
  top: 20%;
  left: 5%;
  right: 0;
  font-size: 5px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  color: #fff;
  pointer-events: none;

  @media (min-width: 768px) {
    font-size: 6px;
    top: 25%;
  }

  @media (min-width: 1024px) {
    font-size: 8px;
    top: 20%;
  }

  @media (min-width: 1280px) {
    font-size: 11px;
    left: 7%;
  }
}
</style>
