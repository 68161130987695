<script>
import { vMaska } from 'maska';

export default {
  props: {
    mix: String,
    modType: {
      required: true,
      validator: v => ['text', 'tel'].includes(v),
    },
    modView: {
      required: true,
      validator: v => ['standard', 'success', 'submitted'].includes(v),
    },

    modelValue: String,
    label: String,
    name: String,
    placeholder: String,
  },

  directives: {
    maska: vMaska,
  },

  emits: ['update:modelValue'],

  data() {
    return {
      value: '',

      maskBoundObject: {
        masked: "",
        unmasked: "",
        completed: false
      }
    }
  },

  methods: {
    handleMaskaInput(e) {
      // помимо maska инпут генерирует и свои input-события
      // но нам они не нужны, потому мы их игнорируем
      if (!e.detail) {
        return;
      }

      if (!e.detail?.completed) {
        this.$emit('update:modelValue', '');
        return;
      }

      this.$emit('update:modelValue', e.detail.unmasked);
    }
  },
}
</script>

<template>
  <label v-bem="{ name: 'input', mods: { modType, modView }, mix }">
    <input
      v-if="modType !== 'tel'"
      class="input__field"
      :value="modelValue"
      :id="name"
      :type="modType"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
    >

    <input
      v-if="modType === 'tel'"
      class="input__field"
      :value="modelValue"
      v-maska="maskBoundObject"
      data-maska="+375 ## ### ## ##"
      :id="name"
      :type="modType"
      :placeholder="placeholder"
      @input="handleMaskaInput"
    />

    <label :for="name" class="input__label">{{ label }}</label>
  </label>
</template>

<style scoped lang="scss">
.input {
  position: relative;
  display: flex;
  padding: 16px 28px;
  border-radius: 16px;
  border: 1px solid #D6D6D6;
  transition: border-color .15s ease-in-out;

  @media (min-width: 1024px) {
    border-radius: 18px;
    padding: 20px 36px;
  }

  @media (min-width: 1280px) {
    border-width: 2px;
    border-radius: 24px;
    padding: 28px 48px;
  }

  &:hover, &:focus-within {
    border-color: #000;

    .input__label {
      color: #000;
    }
  }
}

.input_view_success {
  &, &:hover, &:focus-within {
    border-color: #7DD835;

    .input__label {
      color: #7DD835;
    }
  }
}

.input_view_submitted {
  .input__field {
    color: #7DD835;
  }
}

.input__field {
  width: 100%;
  border: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
  outline: none;
  resize: none;
  height: 20px;
  font-weight: 500;

  @media (min-width: 1024px) {
    font-size: 18px;
    height: 22px;
    line-height: 22px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    height: 32px;
    line-height: 32px;
  }

  &::placeholder {
    color: #D6D6D6;
  }
}

.input__label {
  position: absolute;
  left: 36px;
  bottom: 0;
  padding: 0 6px;
  transform: translateY(50%);
  font-size: 7px;
  font-weight: 600;
  background: var(--input-label-bg, #fff); // ужасный костыль для переопределения цвета на случай другого бэкграунда у родителя
  color: #D6D6D6;
  transition: color .15s ease-in-out;

  @media (min-width: 1280px) {
    left: 64px;
    padding: 0 8px;
    font-size: 12px;
  }
}
</style>
