<script>
import Header from '../../components/Header/Header';
import LeadSection from '../../components/LeadSection/LeadSection';
import CategoriesSection from '../../components/CategoriesSection/CategoriesSection';
import FeedbackSection from '../../components/FeedbackSection/FeedbackSection';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection.vue';
import ProductsSection from '../../components/ProductsSection/ProductsSection.vue';
import MoreSection from '../../components/MoreSection/MoreSection.vue';
import FaqSection from '../../components/FaqSection/FaqSection.vue';
import Footer from '../../components/Footer/Footer.vue';

import shuffle from '../../helpers/shuffle';

const FAKE_SEARCH_RESULTS = [
  'Peugeot Boxer 2006-2013',
  'VW Touran 1 2003-2010',
  'Volvo XC90 P28 2002-2014',
  'Volvo XC90 P28 2002-2014',
  'Citroen Berlingo B9 2008-2018',
  'Ford Mondeo 4 MK4 2007-2013',
  'Opel Zafira A 1999-2005',
  'Citroen C4 Grand Picasso 2006-2013',
  'BMW E38(до рестайлинга) 1994-1998',
  'Honda HR-V 1998-2006',
  'Toyota Camry 5 2001-2006',
  'Skoda Fabia 1999-2007',
  'Mercedes C-klasse W203 2000-2006',
];

const FAKE_PRODUCTS = [
  {
    id: 1,
    imageUrl: require('../../../public/mock-assets/product-1.png'),
    title: 'Порог правый Peugeot Boxer 2006-2013',
    price: 50,
  },
  {
    id: 2,
    imageUrl: require('../../../public/mock-assets/product-2.png'),
    title: 'Арка Geely Emgrand X7 (NL-1) 2013-2017',
    price: 1,
  },
  {
    id: 3,
    imageUrl: require('../../../public/mock-assets/product-3.png'),
    title: 'Порог Volvo XC90 P28 2002-2014',
    price: 999,
  },
  {
    id: 4,
    imageUrl: require('../../../public/mock-assets/product-4.png'),
    title: 'Пороги Ford Mondeo 4 MK4 2007-2013',
    price: 10,
  },
  {
    id: 5,
    imageUrl: require('../../../public/mock-assets/product-5.png'),
    title: 'Арка Volkswagen Sharan 2000-2010',
    price: 56,
  },
  {
    id: 6,
    imageUrl: require('../../../public/mock-assets/product-6.png'),
    title: 'Порог Volkswagen Touran 1 2003-2010',
    price: 34,
  },
  {
    id: 7,
    imageUrl: require('../../../public/mock-assets/product-1.png'),
    title: 'Порог Citroen Berlingo B9 2008-2018 ',
    price: 9876,
  },
  {
    id: 8,
    imageUrl: require('../../../public/mock-assets/product-2.png'),
    title: 'Пороги Opel Zafira A 1999-2005',
    price: 50,
  },
  {
    id: 9,
    imageUrl: require('../../../public/mock-assets/product-3.png'),
    title: 'Порог Peugeot Boxer 2006-2013',
    price: 5,
  },
  {
    id: 10,
    imageUrl: require('../../../public/mock-assets/product-4.png'),
    title: 'Арка Geely Emgrand X7',
    price: 134,
  },
  {
    id: 11,
    imageUrl: require('../../../public/mock-assets/product-5.png'),
    title: 'Порог Volvo XC90',
    price: 8,
  },
  {
    id: 12,
    imageUrl: require('../../../public/mock-assets/product-6.png'),
    title: 'Пороги Ford Mondeo 2007-2013',
    price: 52,
  },
  {
    id: 13,
    imageUrl: require('../../../public/mock-assets/product-1.png'),
    title: 'Арка Volkswagen',
    price: 150,
  },
  {
    id: 14,
    imageUrl: require('../../../public/mock-assets/product-2.png'),
    title: 'Порог Volkswagen Touran 1 2003-2010',
    price: 502,
  },
  {
    id: 15,
    imageUrl: require('../../../public/mock-assets/product-3.png'),
    title: 'Порог Citroen Berlingo B9 2008-2018 ',
    price: 67,
  },
  {
    id: 16,
    imageUrl: require('../../../public/mock-assets/product-4.png'),
    title: 'Пороги Opel Zafira A 1999-2005',
    price: 50,
  },
]

export default {
  components: {
    Header,
    LeadSection,
    CategoriesSection,
    FeedbackSection,
    FeaturesSection,
    ProductsSection,
    MoreSection,
    FaqSection,
    Footer,
  },

  data() {
    return {
      searchResults: null,

      // TODO: вероятно нужно эмулировать загрузку оных?
      products: FAKE_PRODUCTS,
    }
  },

  methods: {
    handleSearchInput(query) {
      if (!query) {
        this.searchResults = null;
        return;
      }

      // эмулируем отсутствие подсказок
      if (Math.random() < .25) {
        this.searchResults = [];
      }

      const shuffledSearchResults = shuffle([...FAKE_SEARCH_RESULTS]);
      const randomLengthSearchResults = shuffledSearchResults.slice(0, Math.floor(Math.random() * shuffledSearchResults.length));

      this.searchResults = randomLengthSearchResults.map(item => ({
        // TODO: проверить URL!
        url: '/catalog/product/1',
        label: `<b>${query}</b> ${item}`,
      }));
    }
  }
}
</script>

<template>
  <Header/>

  <LeadSection
    mix="home-view__lead-section"
    :search-results="searchResults"
    @search-input="handleSearchInput"
  />

  <CategoriesSection
    mix="home-view__categories-section"
  />

  <FeedbackSection
    id="feedback"
    mod-view="girl"
    headline="Наш каталог охватывает более 2000+ моделей авто"
  />

  <FeaturesSection/>

  <ProductsSection :products="products"/>

  <MoreSection/>

  <FaqSection
    mix="home-view__faq-section"
  />

  <FeedbackSection
    mix="home-view__last-feedback-section"
    id="feedback"
    mod-view="boy"
    headline="Сварщики выбирают porogov.net"
  />

  <Footer/>
</template>

<style lang="scss" scoped>
  .home-view__lead-section {
    border-top-width: 16px;

    @media (min-width: 768px) {
      border-top-width: 36px;
    }

    @media (min-width: 1024px) {
      border-top-width: 48px;
    }

    @media (min-width: 1280px) {
      border-top-width: 64px;
    }
  }

  .home-view__categories-section {
    border-top-width: 8px;

    @media (min-width: 768px) {
      border-top-width: 18px;
    }

    @media (min-width: 1024px) {
      border-top-width: 24px;
    }

    @media (min-width: 1280px) {
      border-top-width: 32px;
    }
  }

  .home-view__faq-section {
    border-top-width: 24px;

    @media (min-width: 768px) {
      border-top-width: 36px;
    }

    @media (min-width: 1024px) {
      border-top-width: 48px;
    }

    @media (min-width: 1280px) {
      border-top-width: 64px;
    }
  }

  .home-view__last-feedback-section {
    // переопределяем нижний отступ, чтобы он не выпирал за футер
    // при этом делаем его всё равно большим, чтобы тень было видно
    padding-bottom: 100px;
    margin-bottom: -100px;
  }
</style>
