import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView/HomeView';

const routes = [
    { path: '/', component: HomeView },
    // TODO: add 404 page
    // { path: '/:pathMatch(.*)*', component: P404 },
]

const router = createRouter( { routes, history: createWebHistory(process.env.BASE_URL) })

export default router;
