<script>
export default {
  props: {
    mix: String,
  },

  components: {
  },

  data() {
    return {
    }
  },

  methods: {
  }
}
</script>

<template>
  <section v-bem="{ name: 'section', mix }">
    <div class="section__body">
      <slot/>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section {
  $shadowCompensation: 500px;

  overflow: hidden;
  padding: $shadowCompensation 0;
  margin: -$shadowCompensation 0;
  border-top: 16px solid transparent;

  @media (min-width: 768px) {
    border-top-width: 36px;
  }

  @media (min-width: 1024px) {
    border-top-width: 48px;
  }

  @media (min-width: 1280px) {
    border-top-width: 64px;
  }

  & + & {
    margin-top: -2 * $shadowCompensation;
  }
}

.section__body {
  position: relative;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 0 36px;
  }
}
</style>
