<script>
export default {
  props: {
    mix: String,
    modType: {
      required: true,
      validator: v => ['primary', 'secondary'].includes(v),
    },
    modView: {
      validator: v => ['standard', 'loading', 'failed', 'submitted'].includes(v),
    },
    modDisabled: Boolean,
    type: {
      default: 'button',
      validator: v => ['button', 'submit'].includes(v),
    },
    as: {
      default: 'button',
      validator: v => ['a', 'button'].includes(v),
    },
  },
}
</script>

<template>
  <component
    :is="as"
    :type="as === 'button' ? type : undefined"
    v-bem="{ name: 'button', mods: { modType, modView, modDisabled }, mix }"
    :disabled="modDisabled"
  >
    <slot/>
  </component>
</template>

<style scoped lang="scss">
.button {
  display: inline-block;
  overflow: visible;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font: inherit;
  font-weight: 700;
  border: 0;
  border-radius: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  user-select: none;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:focus,
  &:hover {
    outline: 0;
    -webkit-tap-highlight-color: rgba(#000, 0);
  }
}

.button_type_primary {
  width: 100%;
  height: 54px;
  font-size: 15px;
  line-height: 54px;
  border-radius: 15px;
  background: #7DD835;
  color: #fff;
  box-shadow: 0 8px 36px rgba(125, 216, 53, 0.5);
  transition: box-shadow .15s ease-in-out, background .15s ease-in-out;

  @media (min-width: 1024px) {
    height: 64px;
    font-size: 18px;
    line-height: 64px;
    border-radius: 18px;
    box-shadow: 0 12px 60px rgba(125, 216, 53, 0.5);
  }

  @media (min-width: 1280px) {
    height: 88px;
    font-size: 24px;
    line-height: 88px;
    border-radius: 24px;
  }

  &:hover {
    box-shadow: 0 8px 36px rgba(125, 216, 53, 0.5), 0 8px 36px rgba(125, 216, 53, 0.5);

    @media (min-width: 1024px) {
      box-shadow: 0 12px 60px rgba(125, 216, 53, 0.5), 0 12px 60px rgba(125, 216, 53, 0.5);
    }
  }

  &.button_disabled {
    cursor: not-allowed;

    &, &:hover, &:focus {
      color: #AFADAD;
      background: #E9E9E9;
      box-shadow: none;
    }
  }

  &.button_view_loading {
    cursor: progress;

    &, &:hover, &:focus {
      background: #202020;
      box-shadow: 0 8px 36px rgba(125, 216, 53, 0.5), 0 8px 36px rgba(125, 216, 53, 0.5);

      @media (min-width: 1024px) {
        box-shadow: 0 12px 60px rgba(125, 216, 53, 0.5), 0 12px 60px rgba(125, 216, 53, 0.5);
      }
    }
  }

  &.button_view_failed {
    cursor: not-allowed;

    &, &:hover, &:focus {
      background: #D83535;
      box-shadow: inset 0 6px 60px rgba(0, 0, 0, 0.09), 0 8px 36px #D03333;

      @media (min-width: 1024px) {
        box-shadow: inset 0 8.80567px 110.071px rgba(0, 0, 0, 0.09), 0 12px 60px #D03333;
      }
    }
  }

  &.button_view_submitted {
    pointer-events: none;
  }
}

.button_type_secondary {
  min-width: 100px;
  height: 35px;
  padding: 0 8px;
  font-size: 11px;
  line-height: 31px;
  border: 2px solid #000;
  border-radius: 8px;
  background: #fff;
  color: #000;
  transition: transform .15s ease-in-out;

  @media (min-width: 1024px) {
    height: 40px;
    font-size: 13px;
    line-height: 36px;
    border-radius: 10px;
  }

  @media (min-width: 1280px) {
    height: 56px;
    font-size: 18px;
    line-height: 52px;
    border-radius: 16px;
  }

  &:hover {
    transform: scale(1.1);
  }
}
</style>
