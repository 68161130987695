<script>
import Section from '../Section/Section.vue';

export default {
  props: {
    mix: String,
  },

  components: {
    Section
  },

  data() {
    return {
    }
  },

  methods: {
  }
}
</script>

<template>
  <Section :mix="`${mix || ''} categories-section`">
    <div class="categories-section__list">
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/catalog/category/porogi" class="categories-section__item categories-section__item_slug_porogi">
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/porogi@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/porogi@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/porogi@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Пороги</div>
      </RouterLink>
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/catalog/category/arki" class="categories-section__item categories-section__item_slug_arki">
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/arki@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/arki@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/arki@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Арки</div>
      </RouterLink>
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/catalog/category/remkomplekti-dnisha" class="categories-section__item categories-section__item_slug_remkomplekti-dnisha">
        <!-- TODO: заменить везде на webp? -->
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/remkomplekti-dnisha@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/remkomplekti-dnisha@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/remkomplekti-dnisha@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Ремкомплекты днища</div>
      </RouterLink>
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/catalog/category/usiliteli-porogov" class="categories-section__item categories-section__item_slug_usiliteli-porogov">
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/usiliteli-porogov@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/usiliteli-porogov@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/usiliteli-porogov@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Усилители порогов</div>
      </RouterLink>
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/catalog/category/remkomplekti-dverey" class="categories-section__item categories-section__item_slug_remkomplekti-dverey">
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/remkomplekti-dverey@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/remkomplekti-dverey@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/remkomplekti-dverey@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Ремкомплекты дверей</div>
      </RouterLink>
      <!-- TODO: проверить ссылку! -->
      <RouterLink to="/zakaz" class="categories-section__item categories-section__item_slug_zakaz">
        <!-- TODO: тут картинка кривая! -->
        <img class="categories-section__img visibility_type_mobile-only" src="./assets/zakaz@mobile.png"/>
        <img class="categories-section__img visibility_type_tablet-and-desktop-only" src="./assets/zakaz@tablet.png"/>
        <img class="categories-section__img visibility_type_desktop-wide-only" src="./assets/zakaz@desktop-wide.png"/>
        <div class="categories-section__filler"></div>
        <div class="categories-section__name">Под заказ по&nbsp;вашим меркам</div>
      </RouterLink>
    </div>
  </Section>
</template>

<style lang="scss" scoped>
.categories-section__list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    gap: 18px;
  }

  @media (min-width: 1024px) {
    gap: 24px;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
}

.categories-section__item {
  flex-grow: 1;
  width: calc(50% - 8px);
  position: relative;
  color: #000;
  text-decoration: none;

  @media (min-width: 768px) {
    width: calc(50% - 18px);
  }

  @media (min-width: 1024px) {
    width: calc(50% - 24px);
  }

  @media (min-width: 1280px) {
    width: calc(50% - 32px);
  }

  &:hover {
    .categories-section__name {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-thickness: 2px;

      @media (min-width: 768px) {
        text-underline-offset: 4px;
        text-decoration-thickness: 4px;
      }

      @media (min-width: 1280px) {
        text-underline-offset: 6px;
        text-decoration-thickness: 6px;
      }
    }
  }
}

.categories-section__item_slug_porogi .categories-section__img {
  bottom: 0;
  right: 0;
  height: 102%;
}

.categories-section__item_slug_arki .categories-section__img {
  top: 0;
  left: 0;
  height: 100%;
}

.categories-section__item_slug_remkomplekti-dnisha .categories-section__img {
  top: 0;
  bottom: 0;
  left: -6%;
  height: 100%;
}

.categories-section__item_slug_usiliteli-porogov .categories-section__img {
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.categories-section__item_slug_remkomplekti-dverey .categories-section__img {
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.categories-section__item_slug_zakaz .categories-section__img {
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.categories-section__img {
  position: absolute;
  max-width: none;
  filter: drop-shadow(0 2px 20px rgba(#000, .25));

  @media (min-width: 768px) {
    filter: drop-shadow(0 4px 40px rgba(#000, .25));
  }

  @media (min-width: 1024px) {
    filter: drop-shadow(0 5px 50px rgba(#000, .25));
  }

  @media (min-width: 1280px) {
    filter: drop-shadow(0 7px 70px rgba(#000, .25));
  }
}

.categories-section__filler {
  padding-top: 160%;
}

.categories-section__name {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 12px 16px;
  text-transform: lowercase;
  font-size: 14px;
  line-height: 1;
  font-weight: 800;

  @media (min-width: 768px) {
    padding: 32px;
    font-size: 22px;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    padding: 42px;
    font-size: 28px;
  }

  @media (min-width: 1280px) {
    padding: 64px;
    font-size: 40px;
  }
}
</style>
