<script>
import IconButton from '../IconButton/IconButton.vue';
import Section from '../Section/Section.vue';
import { RouterLink } from 'vue-router';

export default {
  props: {
    products: Array,
  },

  components: {
    RouterLink,

    IconButton,
    Section,
  }
}
</script>

<template>
  <Section>
    <div class="products-section">
      <!-- TODO: проверить ссылку -->
      <RouterLink
        v-for="product in products"
        v-bind:key="product.id"
        :to="`/catalog/product/${product.id}`"
        class="products-section__item product"
      >
        <img
          :src="product.imageUrl"
          :alt="`Фото «${product.title}»`"
          class="products-section__item-img"
        >
        <div class="products-section__item-description">
          <h3 class="products-section__item-title">{{ product.title }}</h3>
          <p class="products-section__item-price">{{ product.price }} BYN</p>
        </div>

        <!-- TODO: что происходит при добавлении? -->
        <IconButton
          as="button"
          mix="products-section__item-button"
          mod-icon="cart-left"
          mod-theme="light"
          mod-size="m"
          text="Добавить в корзину"
        />
      </RouterLink>
    </div>
  </Section>
</template>

<style scoped lang="scss">
.products-section {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 18px;
  }

  @media (min-width: 1024px) {
    gap: 24px;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
}

.products-section__item {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: calc(50% - 16px);
  padding: 8px 16px;
  border-radius: 24px;
  background: #fff;
  text-decoration: none;
  box-shadow: 0 4px 36px rgba(0, 0, 0, .25);
  color: #000;
  transition: transform .15s ease-in-out, box-shadow .15s ease-in-out;

  @media (min-width: 768px) {
    width: calc(25% - 18px);
    padding: 24px 24px 16px;
    border-radius: 26px;
  }

  @media (min-width: 1024px) {
    width: calc(25% - 24px);
    padding: 32px 32px 18px;
    border-radius: 36px;
  }

  @media (min-width: 1280px) {
    width: calc(25% - 32px);
    padding: 42px 42px 24px;
    border-radius: 48px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 36px rgba(0, 0, 0, .5);
  }
}

.products-section__item-img {
  flex-grow: 1;
  display: block;
  object-fit: cover;
  max-width: none;
  width: calc(100% + 16px);
  max-height: 120px;
  margin: 10px -8px 0;

  @media (min-width: 600px) {
    max-height: 180px;
  }

  // не случайно сперва увеличиваем, а потом уменьшаем
  // на 768 меняется количество продуктов в строке, потому продукт становится уже,
  // потому лучше с картинкой поменьше
  @media (min-width: 768px) {
    max-height: 150px;
    width: calc(100% + 32px);
    margin: 10px -16px 0;
  }

  @media (min-width: 1024px) {
    width: calc(100% + 48px);
    margin: 0 -24px 0;
  }

  @media (min-width: 1280px) {
    width: calc(100% + 68px);
    max-height: 200px;
    margin: 0 -34px 0;
  }
}

.products-section__item-description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  text-align: center;
  box-shadow: 0 -24px 16px #fff;

  @media (min-width: 1024px) {
    margin-top: 24px;
    box-shadow: 0 -48px 16px #fff;
  }
}

.products-section__item-title {
  margin: 0;
  font-size: 14px;
  line-height: 1.35;
  text-transform: lowercase;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 9px;
    line-height: 1.24;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
}

.products-section__item-price {
  margin: 16px 0 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.24;

  @media (min-width: 768px) {
    margin-top: 20px;
    font-size: 10px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
    font-size: 13px;
  }

  @media (min-width: 1280px) {
    margin-top: 40px;
    font-size: 18px;
  }
}

.products-section__item-button {
  position: absolute;
  right: 12px;
  top: 12px;
  box-shadow: 0 4px 36px rgba(#000, .25);

  @media (min-width: 768px) {
    box-shadow: 0 4px 40px rgba(#000, .25);
  }

  @media (min-width: 1024px) {
    top: 16px;
    right: 16px;
    box-shadow: 0 5px 54px rgba(#000, .25);
  }

  @media (min-width: 1280px) {
    top: 20px;
    right: 20px;
    box-shadow: 0 7px 73px rgba(#000, .25);
  }

  &:hover {
    box-shadow: 0 4px 36px rgba(#000, .5);

    @media (min-width: 1024px) {
      box-shadow: 0 4px 40px rgba(#000, .5);
    }

    @media (min-width: 1024px) {
      box-shadow: 0 5px 54px rgba(#000, .5);
    }

    @media (min-width: 1280px) {
      box-shadow: 0 7px 73px rgba(#000, .5);
    }
  }
}
</style>
