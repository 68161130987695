export default function bem({ name, mods = {}, mix = [], defaultMods = {} }) {
  let result = [name];

  const addMod = (key, value) => {
    const mod = [name, camelToKebab(normalizeModName(key))];

    if (typeof value !== 'boolean') {
      mod.push(camelToKebab(value));
    }

    result.push(mod.join('_'));
  };

  Object.keys(defaultMods).forEach(key => {
    if (!Object.hasOwn(mods, key) && defaultMods[key]) {
      addMod(key, defaultMods[key]);
    }
  });

  Object.keys(mods).forEach(key => {
    if (mods[key]) {
      addMod(key, mods[key]);
    }
  });

  if (mix) {
    result = result.concat(mix);
  }

  return result.join(' ');
}

function camelToKebab(str) {
  return str.toString().replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function normalizeModName(modName) {
  return modName.replace(/^mod([A-Z])(.*)/, (_, head, tail) => head.toLowerCase() + tail);
}
