<script>
import Button from '../Button/Button.vue';
import Input from '../Input/Input.vue';
import Section from '../Section/Section.vue';

import bem from '../../helpers/bem';

export default {
  props: {
    mix: String,
    modView: {
      required: true,
      validator: v => ['girl', 'boy'].includes(v),
    },

    headline: String,
  },

  components: {
    Button,
    Input,
    Section
  },

  data() {
    return {
      name: '',
      phone: '',
      isFormSent: false,
      isFormSending: false,
      isFormFailed: false,
    };
  },

  methods: {
    bem,

    async handleSubmit() {
      // предотвращаем повторную отправку, на всякий случай :-)
      if (this.isFormSent || this.isFormSending || this.isFormFailed) {
        return;
      }

      this.isFormSending = true;

      // эмуляция отправки запроса
      await new Promise(r => setTimeout(r, 2000));

      alert(JSON.stringify({ name: this.name, phone: `+375${this.phone}` }));

      this.isFormSending = false;

      if (Math.random() < .5) {
        this.isFormSent = true;
      } else {
        this.isFormFailed = true;
      }
    }
  },

  watch: {
    name() {
      this.isFormSent = false;
      this.isFormFailed = false;
    },

    phone() {
      this.isFormSent = false;
      this.isFormFailed = false;
    }
  },

  computed: {
    isFormReady() {
      return this.isNameValid && this.isPhoneValid;
    },

    isNameValid() {
      return !!this.name;
    },

    isPhoneValid() {
      return !!this.phone;
    }
  }
}
</script>

<template>
  <Section :mix="`${mix || ''} ${bem({ name: 'feedback-section', mods: { modView } })}`">
    <div class="feedback-section__block">
      <div class="feedback-section__content">
        <h2 class="feedback-section__headline">{{ headline }}</h2>
        <p class="feedback-section__subheadline">Заполните форму и наш менеджер поможет оформить заказ!</p>

        <form
          class="feedback-section__form"
          @submit.prevent="handleSubmit"
        >
          <div class="feedback-section__form-item">
            <Input
              v-model="name"
              mod-type="text"
              :mod-view="isFormSent ? 'submitted' : (isNameValid ? 'success' : 'standard')"
              name="name"
              label="Имя"
              placeholder="Иван"
            />
          </div>
          <div class="feedback-section__form-item">
            <Input
              v-model="phone"
              mod-type="tel"
              :mod-view="isFormSent ? 'submitted' : (isPhoneValid ? 'success' : 'standard')"
              name="phone"
              label="Телефон"
              placeholder="+375 44 555 66 77"
            />
          </div>
          <div class="feedback-section__form-item">
            <Button
              mix="feedback-section__submit-button"
              mod-type="primary"
              :mod-disabled="!isFormReady"
              :mod-view="isFormFailed ? 'failed' : (isFormSending ? 'loading' : (isFormSent ? 'submitted' : 'standard'))"
              type="submit"
            >
              {{ !isFormSending && !isFormFailed && !isFormSent ? 'Отправить заявку' : '' }}
              {{ isFormSending ? 'Отправляется...' : '' }}
              {{ isFormFailed ? 'Ошибка отправки :(' : '' }}

              <span v-if="isFormSent">
                <svg
                  class="feedback-section__submit-button-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 42 36"
                  width="42"
                  height="36"
                  fill="none"
                >
                  <path fill="#fff" d="M30.08 1.5 16.742 22.346 7.038 16.52 3.4 22.58l15.771 9.465L36.145 5.141l-6.066-3.64Z"/>
                </svg>

                Успешно отправлено
              </span>
            </Button>
          </div>
        </form>
      </div>

      <img v-if="modView === 'girl'" class="feedback-section__img visibility_type_mobile-only" src="./assets/bg-girl@mobile.png" alt="">
      <img v-if="modView === 'girl'" class="feedback-section__img visibility_type_tablet-only" src="./assets/bg-girl@tablet.png" alt="">
      <img v-if="modView === 'girl'" class="feedback-section__img visibility_type_desktop-only" src="./assets/bg-girl@desktop.png" alt="">
      <img v-if="modView === 'girl'" class="feedback-section__img visibility_type_desktop-wide-only" src="./assets/bg-girl@desktop-wide.png" alt="">

      <img v-if="modView === 'boy'" class="feedback-section__img visibility_type_mobile-only" src="./assets/bg-boy@mobile.png" alt="">
      <img v-if="modView === 'boy'" class="feedback-section__img visibility_type_tablet-only" src="./assets/bg-boy@tablet.png" alt="">
      <img v-if="modView === 'boy'" class="feedback-section__img visibility_type_desktop-only" src="./assets/bg-boy@desktop.png" alt="">
      <img v-if="modView === 'boy'" class="feedback-section__img visibility_type_desktop-wide-only" src="./assets/bg-boy@desktop-wide.png" alt="">
    </div>
  </Section>
</template>

<style scoped lang="scss">
.feedback-section__block {
  position: relative;
  overflow: hidden;
  padding: 16px 24px 0;
  border-radius: 10px;
  background: #fff;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.25));

  @media (min-width: 768px) {
    border-radius: 24px;
    padding: 36px;
    // формально эта тень не захватывает чуть выпирающие картинки фона
    // но добавить тень ещё и им довольно-таки сложно
    // и выпирают они совсем на чуть-чуть
    // потому забили на это :-)
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1024px) {
    border-radius: 32px;
    padding: 42px 48px 52px;
    filter: drop-shadow(0px 5.32843px 53.2843px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1280px) {
    border-radius: 40px;
    padding: 64px 64px 72px;
    filter: drop-shadow(0px 7.35632px 73.5632px rgba(0, 0, 0, 0.25));
  }
}

.feedback-section__content {
  max-width: 360px;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 370px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    max-width: 480px;
  }

  @media (min-width: 1280px) {
    max-width: 680px;
  }
}

.feedback-section__headline {
  font-size: 28px;
  font-weight: 800;
  line-height: 1;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 28px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 42px;
    line-height: 40px;
  }

  @media (min-width: 1280px) {
    font-size: 60px;
    line-height: 52px;
  }
}

.feedback-section__form {
  margin-top: 16px;

  @media (min-width: 768px) {
    max-width: 240px;
  }

  @media (min-width: 1024px) {
    max-width: 300px;
    margin-top: 24px;
  }

  @media (min-width: 1280px) {
    max-width: 420px;
    margin-top: 30px;
  }
}

.feedback-section__form-item {
  & + & {
    margin-top: 16px;

    @media (min-width: 1280px) {
      margin-top: 24px;
    }
  }
}

.feedback-section__submit-button {
  // чтобы тень от кнопки накладывалась на соседей
  position: relative;
}

.feedback-section__submit-button-icon {
  width: 1.4em;
  height: 1em;
  vertical-align: -0.1em;
}

.feedback-section__img {
  display: block;
  max-width: none;

  @media (min-width: 768px) {
    position: absolute;
    z-index: -1;
  }
}

.feedback-section__subheadline {
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  line-height: 1.15;
  font-weight: 800;
  color: #868686;

  @media (min-width: 768px) {
    max-width: 320px;
    margin-top: 12px;
    text-align: left;
    font-size: 14px;
    line-height: 1.35;
    color: #404040;
  }

  @media (min-width: 1024px) {
    max-width: 420px;
    margin-top: 18px;
    font-size: 18px;
  }

  @media (min-width: 1280px) {
    max-width: 580px;
    margin-top: 24px;
    font-size: 24px;
  }
}

.feedback-section_view_girl {
  .feedback-section__block {
    overflow: hidden;

    @media (min-width: 768px) {
      overflow: visible;
      background: #F5F9FC linear-gradient(310.3deg, #F4F8FB 1.15%, rgba(246, 250, 253, 0) 66.29%);
      --input-label-bg: #F5F9FC;
    }
  }

  .feedback-section__img {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -50px;

    // для десктопов стили такие же, потому отдельные тут не заданы
    @media (min-width: 768px) {
      bottom: 0;
      right: 0;
      height: 102.8%;
      width: auto;
      margin-left: 0;
    }
  }
}

.feedback-section_view_boy {
  .feedback-section__block {
    overflow: visible;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    @media (min-width: 1280px) {
      padding-bottom: 12px;
    }
  }

  .feedback-section__headline {
    @media (min-width: 768px) {
      font-size: 38px;
      line-height: .93;
    }

    @media (min-width: 1024px) {
      font-size: 50px;
    }

    @media (min-width: 1280px) {
      font-size: 69px;
    }
  }

  .feedback-section__form {
    @media (min-width: 768px) {
      // дизайнер просто отмасштабировал форму на .75
      // потому мы делаем так же, ибо иначе придётся для каждого компонента сделать свой размер
      transform: scale(.75);
      transform-origin: 0 0;
      background: #fff;
      // в дизайне filter blur на фоновом блоке; но у нас filter blur работает иначе, потому заменяем просто на тень
      box-shadow: 32px 0 32px #fff;
    }

    @media (min-width: 1024px) {
      transform: scale(.8);
    }
  }

  .feedback-section__img {
    width: calc(102% + 48px);
    margin-left: calc(-24px - 1%);

    // для десктопов стили такие же, потому отдельные тут не заданы
    @media (min-width: 768px) {
      bottom: 0;
      right: -2%;
      height: 104%;
      width: auto;
      margin-left: 0;
    }

    @media (min-width: 1280px) {
      height: 103%;
    }
  }
}
</style>
