<script>
import { RouterLink } from 'vue-router';
import IconButton from '../IconButton/IconButton.vue';

export default {
  components: {
    IconButton,
    RouterLink,
  },

  data() {
    return {
    }
  },

  methods: {
  }
}
</script>

<template>
  <footer class="footer">
    <div class="footer__body">
      <h3 class="footer__company-name">
        porogov.net
      </h3>

      <p class="footer__company-description">
        Производитель кузовных<br/>ремкомплектов
      </p>

      <div class="footer__menu footer__menu_type_catalog">
        <!-- TODO: проверить ссылку -->
        <h3 class="footer__menu-title"><RouterLink to="/catalog" class="footer__headline-link">Каталог</RouterLink></h3>

        <ul class="footer__menu-items">
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/catalog/category/arki" class="footer__menu-link">Арки</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/catalog/category/porogi" class="footer__menu-link">Пороги</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/catalog/category/usiliteli-porogov" class="footer__menu-link">Усилители порогов</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/catalog/category/remkomplekti-dnisha" class="footer__menu-link">Ремкомплекты днища</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/catalog/category/remkomplekti-dverey" class="footer__menu-link">Ремкомплекты дверей</RouterLink></li>
        </ul>
      </div>

      <div class="footer__menu footer__menu_type_wholesale">
        <!-- TODO: проверить ссылку -->
        <h3 class="footer__menu-title"><RouterLink to="/wholesale" class="footer__headline-link">Опт</RouterLink></h3>
      </div>

      <div class="footer__contacts">
        <h3 class="footer__phone"><a href="tel:+375291788811" class="footer__headline-link">+375 29 178 88 11</a></h3>

        <table class="footer__schedule">
          <tbody>
          <tr>
            <td>пн – пт</td>
            <td>10:00 – 19:00</td>
          </tr>
          <tr>
            <td>сб</td>
            <td>11:00 – 18:00</td>
          </tr>
          </tbody>
        </table>

        <address class="footer__address">
          Минск, Беларусь<br/>
          ул. Калиновского, 111а, 206
        </address>
      </div>

      <div class="footer__socials">
        <IconButton
            as="a"
            mix="footer__social"
            mod-size="l"
            mod-theme="dark"
            mod-icon="whatsapp"
            href="https://wa.me/message/JFFJXOFCL7UOK1"
            text="WhatsApp"
        />

        <!-- вернуть, если появится вайбер -->
        <!--
        <IconButton
          as="a"
          mix="footer__social"
          mod-size="l"
          mod-theme="dark"
          mod-icon="viber"
          href="#"
          text="Viber"
        />
        -->

        <IconButton
            as="a"
            mix="footer__social"
            mod-size="l"
            mod-theme="dark"
            mod-icon="vk"
            href="https://vk.com/porogiarki"
            text="VK"
        />

        <IconButton
            as="a"
            mix="footer__social"
            mod-size="l"
            mod-theme="dark"
            mod-icon="telegram"
            href="https://t.me/managerporogovnet"
            text="Telegram"
        />

        <IconButton
            as="a"
            mix="footer__social"
            mod-size="l"
            mod-theme="dark"
            mod-icon="instagram"
            href="https://www.instagram.com/porogiarki/"
            text="Instagram"
        />
      </div>

      <div class="footer__menu footer__menu_type_purchase">
        <ul class="footer__menu-items">
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/return" class="footer__menu-link">Условия возврата</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/delivery" class="footer__menu-link">Доставка</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/payment" class="footer__menu-link">Оплата</RouterLink></li>
          <!-- TODO: проверить ссылку -->
          <li class="footer__menu-item"><RouterLink to="/checkout" class="footer__menu-link">Корзина</RouterLink></li>
        </ul>
      </div>

      <p class="footer__legal">
        ООО «Кузовсталь»<br/>
        р/сч BY02MTBK30120001093300079963<br/>
        ЗАО «МТБанк»,<br/>
        БИК Банка MTBKBY22<br/>
        220007, г. Минск, ул. Толстого, 10<br/>
        2017 – {{ new Date().getFullYear() }} © УНП 192806772<br/>
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  max-width: 1280px;
  margin: 16px auto 0;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-top: 36px;
    padding: 0 36px;
  }

  @media (min-width: 1024px) {
    margin-top: 48px;
  }

  @media (min-width: 1280px) {
    margin-top: 64px;
  }
}

.footer__body {
  display: grid;
  grid-template-areas:
      "company-name"
      "company-description"
      "wholesale-menu"
      "catalog-menu"
      "contacts"
      "socials"
      "purchase-menu"
      "legal";
  padding: 16px;
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 7.57536px -2.52512px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 1.76758px 17.6758px rgba(0, 0, 0, 0.25));

  @media (min-width: 768px) {
    grid-template-areas:
        "company-name catalog-menu wholesale-menu contacts socials"
        "company-description catalog-menu wholesale-menu contacts purchase-menu"
        "legal catalog-menu wholesale-menu contacts purchase-menu";
    padding: 28px 32px 20px;
    text-align: left;
    border-radius: 24px;
    box-shadow: 0 0 17.1672px -5.72239px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 4.00567px 40.0567px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1024px) {
    padding: 38px 48px 28px;
    border-radius: 32px;
    box-shadow: 0 0 22.8725px -7.62415px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 5.33691px 53.3691px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1280px) {
    padding: 52px 64px 64px;
    border-radius: 40px;
    box-shadow: 0 0 31.5349px -10.5116px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 7.35815px 73.5815px rgba(0, 0, 0, 0.25));
  }
}

.footer__company-name {
  grid-area: company-name;

  // отступы и их компенсация для того, чтобы выпирающие части текста не обрезались из-за background-clip
  padding: 16px;
  margin: -16px;

  font-size: 28px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  background: radial-gradient(122.52% 294.23% at -18.63% 50%, #000000 24.31%, rgba(0, 0, 0, 0.61) 51.91%, #000000 81.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 1;
    text-align: left;
    background: radial-gradient(122.52% 294.23% at -18.63% 50%, #000000 27.95%, rgba(0, 0, 0, 0.7) 41.49%, rgba(0, 0, 0, 0.73) 64.41%, #000000 78.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }

  @media (min-width: 1280px) {
    font-size: 36px;
  }
}

.footer__company-description {
  grid-area: company-description;
  margin-top: 4px;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 768px) {
    margin-top: 0;
    font-size: 10px;
    line-height: 1.3;
    font-weight: 700;
  }

  @media (min-width: 1024px) {
    margin-top: 4px;
    font-size: 13px;
  }

  @media (min-width: 1280px) {
    margin-top: 12px;
    font-size: 18px;
  }
}

.footer__menu {
  text-transform: lowercase;
}

.footer__menu_type_wholesale {
  grid-area: wholesale-menu;
  margin-top: 12px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.footer__menu_type_catalog {
  grid-area: catalog-menu;
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 56px;
  }

  @media (min-width: 1280px) {
    padding-left: 72px;
  }
}

.footer__menu_type_purchase {
  grid-area: purchase-menu;
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 7px;
    padding-left: 12px;
    text-align: right;
  }

  @media (min-width: 1024px) {
    margin-top: 18px;
  }

  @media (min-width: 1280px) {
    margin-top: 20px;
    padding-left: 20px;
  }
}

.footer__menu-title {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (min-width: 1280px) {
    font-size: 28px;
    line-height: 36px;
  }

  + .footer__menu-items {
    margin-top: 12px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    @media (min-width: 1280px) {
      margin-top: 4px;
    }
  }
}

.footer__menu-item {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 10px;
    line-height: 1.9;
    font-weight: 700;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    font-size: 12px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
  }

  & + & {
    margin-top: 12px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}

.footer__headline-link {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;

    @media (min-width: 1024px) {
      text-underline-offset: 3px;
      text-decoration-thickness: 3px;
    }

    @media (min-width: 1280px) {
      text-underline-offset: 4px;
      text-decoration-thickness: 4px;
    }
  }
}

.footer__menu-link {
  color: #A0A0A0;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.footer__contacts {
  grid-area: contacts;
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 52px;
  }

  @media (min-width: 1280px) {
    padding-left: 64px;
  }
}

.footer__phone {
  font-size: 28px;
  line-height: 1;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 21px;
    white-space: nowrap;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 36px;
  }

  .footer__headline-link {
    @media (min-width: 1280px) {
      text-decoration-thickness: 4px;
    }
  }
}

.footer__schedule {
  margin: 36px auto 0;
  width: 100%;
  max-width: 160px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  border-spacing: 0;

  @media (min-width: 768px) {
    max-width: 120px;
    margin: 20px 0 0;
    font-size: 10px;
    line-height: 15px;
  }

  @media (min-width: 1024px) {
    max-width: 160px;
    font-size: 13px;
    line-height: 20px;
  }

  @media (min-width: 1280px) {
    max-width: 220px;
    margin-top: 28px;
    font-size: 18px;
    line-height: 28px;
  }

  td {
    padding: 0;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.footer__address {
  margin-top: 24px;
  font-size: 13px;
  line-height: 1.8;
  font-weight: 700;
  font-style: normal;

  @media (min-width: 768px) {
    margin-top: 14px;
    font-size: 10px;
    line-height: 1.8;
  }

  @media (min-width: 1024px) {
    margin-top: 18px;
    font-size: 13px;
  }

  @media (min-width: 1280px) {
    margin-top: 28px;
    font-size: 18px;
  }
}

.footer__socials {
  grid-area: socials;
  margin-top: 24px;
  display: flex;
  gap: 10px;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 16px;
    gap: 4px;
    justify-content: flex-end;
  }

  @media (min-width: 1024px) {
    gap: 8px;
  }

  @media (min-width: 1280px) {
    padding-left: 20px;
    gap: 10px;
  }
}

.footer__social {
  flex-shrink: 0;

  @media (min-width: 768px) {
    width: 16px;
    height: 16px;

    // жизнь штука суровая
    :deep(svg) {
      transform: scale(.5);
    }
  }

  @media (min-width: 1024px) {
    width: 22px;
    height: 22px;

    :deep(svg) {
      // очень суровая
      transform: scale(.75) !important;
    }
  }

  @media (min-width: 1280px) {
    width: 30px;
    height: 30px;

    :deep(svg) {
      transform: none !important;
    }
  }
}

.footer__legal {
  grid-area: legal;
  margin-top: 24px;
  font-size: 11px;
  line-height: 2;
  font-weight: 700;
  color: #A0A0A0;

  @media (min-width: 768px) {
    margin-top: 0;
    font-size: 7px;
    line-height: 1.4;
    font-weight: 700;
  }

  @media (min-width: 1024px) {
    margin-top: 8px;
    font-size: 9px;
  }

  @media (min-width: 1280px) {
    margin-top: 10px;
    font-size: 12px;
  }
}
</style>
