<script>
import Section from '../Section/Section.vue';

export default {
  components: {
    Section
  }
}
</script>

<template>
  <Section>
    <div class="faq-section">
      <img src="./assets/bg@mobile.png" alt="" class="faq-section__img visibility_type_mobile-only">
      <img src="./assets/bg@tablet.png" alt="" class="faq-section__img visibility_type_tablet-only">
      <img src="./assets/bg@desktop.png" alt="" class="faq-section__img visibility_type_desktop-only">
      <img src="./assets/bg@desktop-wide.png" alt="" class="faq-section__img visibility_type_desktop-wide-only">

      <h2 class="faq-section__headline">
        <div class="faq-section__headline-bg"/>
        <div class="faq-section__headline-text">Отвечаем<br>на часто<br>задаваемые<br>вопросы</div>
      </h2>

      <div class="faq-section__items">
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-1" class="faq-section__item-control" checked>
          <label for="faq-1" class="faq-section__item-label">Из какого материала изготавливаете пороги/арки?</label>
          <div class="faq-section__item-body">
            <p>Ремкомплекты порогов и задних арок мы изготавливаем из оцинкованной стали, специальная автомобильная сталь, марка 08ю. Данная сталь предназначена для сложной и особосложной вытяжки. Толщина стали: для порогов — 1 мм, для арок — 0,8мм.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-2" class="faq-section__item-control">
          <label for="faq-2" class="faq-section__item-label">Хочу пороги из холоднокатаной стали. Мастер сказал, что оцинковка плохо варится.</label>
          <div class="faq-section__item-body">
            <p>Для изготовления нашей продукции мы используем оцинкованную сталь, марки 08ю. Эта сталь хорошо варится полуавтоматом и за счет своей пластичности с ней проще работать в процессе установки порога/арки к автомобилю.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-3" class="faq-section__item-control">
          <label for="faq-3" class="faq-section__item-label">Можно ли сделать порог/арку из 1,2мм?</label>
          <div class="faq-section__item-body">
            <p>Приварка ремкомплекта толщиной 1,2 мм ничего не даст, кроме увеличения веса. При проведении сварочных работ по замене порога/арки, соединяться они будет с остатками заводского металла толщиной 0,6 — 0,8 мм и это место (сам сварочный шов) будет слабым звеном при последующей эксплуатации. Поэтому, для долговечности кузовной детали стоит уделить должное внимание обработке сварного шва.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-4" class="faq-section__item-control">
          <label for="faq-4" class="faq-section__item-label">Как долго прослужит ремкомплект?</label>
          <div class="faq-section__item-body">
            <p>При должной обработке сварного шва ремкомплект прослужит до 5 лет.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-5" class="faq-section__item-control">
          <label for="faq-5" class="faq-section__item-label">Вы даете гарантию на срок службы ремкомплекта?</label>
          <div class="faq-section__item-body">
            <p>Мы даем гарантию на проверку качества нашего ремкомплекта. Гарантийный срок нашей продукции 14 дней, со дня покупки.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-6" class="faq-section__item-control">
          <label for="faq-6" class="faq-section__item-label">Ваши пороги ребристые?</label>
          <div class="faq-section__item-body">
            <p>Нет, наш порог имеет гладкую поверхность. Т. к. при изготовления порогов мы используем качественную сталь (марка 08ю) и профессионализм наших мастеров 😏</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-7" class="faq-section__item-control">
          <label for="faq-7" class="faq-section__item-label">Есть ли доставка по РБ?</label>
          <div class="faq-section__item-body">
            <p>Да, мы отправляем нашу продукцию по всем регионам республики. Узнать стоимость доставки можно позвонив нам по номеру <a href="tel:+375291788811">+375 29 178 88 11</a>.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-8" class="faq-section__item-control">
          <label for="faq-8" class="faq-section__item-label">Что делать, если не нашел, что искал на свой автомобиль?</label>
          <div class="faq-section__item-body">
            <p>Главное, не паникуйте 😊. Позвоните нам по номеру <a href="tel:+375291788811">+375 29 178 88 11</a> или заполните <a href="#feedback">форму для обратного звонка</a>.</p>
          </div>
        </div>
        <div class="faq-section__item faq-item">
          <input type="radio" name="faq" id="faq-9" class="faq-section__item-control">
          <label for="faq-9" class="faq-section__item-label">Нет в наличии ремкомплекта, как долго будете изготавливать?</label>
          <div class="faq-section__item-body">
            <p>Одно из главных преимуществ нашей компании — это изготовления продукции в кратчайшие сроки, а именно, в день оформления заказа.</p>
          </div>
        </div>
      </div>
    </div>
  </Section>
</template>

<style scoped lang="scss">
.faq-section {
  padding: 0 16px 20px;
  border-radius: 10px;
  background: #fff;
  filter: drop-shadow(0px 1.76779px 17.6779px rgba(0, 0, 0, 0.25));

  @media (min-width: 768px) {
    position: relative;
    display: flex;
    padding: 36px;
    border-radius: 24px;
    filter: drop-shadow(0px 4.00413px 40.0413px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1024px) {
    padding: 48px;
    border-radius: 32px;
    filter: drop-shadow(0px 5.33485px 53.3485px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1280px) {
    padding: 60px;
    border-radius: 40px;
    filter: drop-shadow(0px 7.35815px 73.5815px rgba(0, 0, 0, 0.25));
  }
}

.faq-section__img {
  max-width: none;
  width: calc(100% + 32px);
  margin: -3% -16px 0;

  @media (min-width: 768px) {
    flex-shrink: 0;
    flex-grow: 1;
    width: calc(50% + 36px);
    margin: auto 0 0 -36px;
    object-fit: contain;
    object-position: top;
  }

  @media (min-width: 1024px) {
    width: calc(50% + 48px);
    margin-left: -48px;
  }

  @media (min-width: 1280px) {
    width: calc(50% + 60px);
    margin-left: -60px;
  }
}

.faq-section__headline {
  position: relative;
  z-index: 1;
  margin-top: -40%;

  @media (min-width: 768px) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(50% - 72px);
    padding: 36px;
  }

  @media (min-width: 1024px) {
    width: calc(50% - 96px);
    padding: 48px;
    border-radius: 32px;
  }

  @media (min-width: 1280px) {
    width: calc(50% - 128px);
    padding: 64px;
    border-radius: 40px;
  }
}

.faq-section__headline-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  @media (min-width: 768px) {
    background: linear-gradient(to bottom, transparent 0%, #fff 70%);
    border-radius: 24px;
  }
}

.faq-section__headline-text {
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  line-height: 1;
  background: radial-gradient(122.52% 294.23% at -18.63% 50%, #000000 24.31%, rgba(0, 0, 0, 0.61) 51.91%, #000000 81.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 768px) {
    font-size: 37px;
    text-align: left;
  }

  @media (min-width: 1024px) {
    font-size: 50px;
  }

  @media (min-width: 1280px) {
    font-size: 68px;
  }
}

.faq-section__items {
  position: relative;
  z-index: 1;
  margin-top: 24px;
  box-shadow: 0 -8px 32px #fff;
  background: #fff;

  @media (min-width: 768px) {
    flex-shrink: 0;
    flex-grow: 1;
    width: 50%;
    margin-top: 0;
    box-shadow: none;
  }
}

.faq-section__item {
  & + & {
    margin-top: 16px;

    @media (min-width: 768px) {
      margin-top: 12px;
    }

    @media (min-width: 1024px) {
      margin-top: 18px;
    }

    @media (min-width: 1280px) {
      margin-top: 20px;
    }
  }
}

.faq-section__item-label {
  position: relative;
  display: block;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.2;
  color: #AEAEAE;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  @media (min-width: 768px) {
    line-height: 1.35;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    padding-right: 50px;
  }

  @media (min-width: 1280px) {
    font-size: 24px;
    padding-right: 64px;
  }

  &::after {
    content: '+';
    position: absolute;
    top: -8px;
    right: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      top: -6px;
      font-size: 36px;
    }

    @media (min-width: 1280px) {
      top: -8px;
      font-size: 48px;
    }
  }
}

.faq-section__item-body {
  display: none;
  padding-top: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 11px;
  }

  @media (min-width: 1024px) {
    font-size: 15px;
    line-height: 1.4;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
    line-height: 1.5;
  }

  p + p {
    margin-top: 8px;

    @media (min-width: 1280px) {
      margin-top: 16px;
    }
  }

  a {
    color: #000;
  }
}

.faq-section__item-control {
  display: none;

  &:checked ~ .faq-section__item-label {
    color: #000;
    cursor: default;

    &::after {
      content: '−';
    }
  }

  &:checked ~ .faq-section__item-body {
    display: block;
  }
}
</style>
