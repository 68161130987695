import { createApp } from 'vue';

import App from './App';

import router from './router';

import bem from './helpers/bem';

const app = createApp(App);

app.directive('bem', (el, binding) => {
  const { value } = binding;
  el.className = bem(value);
});

app.use(router)
  .mount('#app');
