<script>
import { RouterView } from 'vue-router';

export default {
  components: {
    RouterView,
  },

  data() {
    return {
    }
  },

  methods: {
  },
};
</script>

<template>
  <RouterView/>
</template>

<style lang="scss">
// сброс основных стилей
p, blockquote, dl, dd, figure {
  margin: 0;
}

th, td {
  padding: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

iframe, abbr {
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

caption {
  text-align: left;
}

blockquote, q {
  quotes: none;

  &::before, &::after {
    content: '';
  }
}

article,
aside,
footer,
header,
main,
nav,
section {
  display: block;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}

button {
  display: inline-block;
  outline: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font: inherit;
  line-height: 1;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

// установка основных глобальных стилей документа

html {
  height: 100%;
  background: url('./bg.jpg') no-repeat fixed;
  background-size: cover;
}

body {
  box-sizing: border-box;
  min-width: 340px;
  margin: 0;
  padding: 16px 0;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-size-adjust: 100%;
  color: #000;
  -webkit-font-smoothing: antialiased;

  @media (min-width: 768px) {
    padding: 36px 0;
  }
}

::selection {
  color: #fff;
  background: #000;
}

// в проекте много элементов, которые исчезают или появляются только для отдельных брейкпоинтов
// в основном это изображения, торчащие в разные стороны из блоков
// для них созданы эти отдельные глобальные классы, чтобы не дублировать их в каждом компоненте
// однако, чтобы переопределять скоуп-стили компонентов, им нужно быть !important
// потому так :-)
.visibility_type_mobile-only {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.visibility_type_tablet-only {
  display: none !important;

  @media (min-width: 768px) {
    display: block !important;
  }

  @media (min-width: 1024px) {
    display: none !important;
  }
}

.visibility_type_desktop-only {
  display: none !important;

  @media (min-width: 1024px) {
    display: block !important;
  }

  @media (min-width: 1280px) {
    display: none !important;
  }
}

.visibility_type_tablet-and-desktop-only {
  display: none !important;

  @media (min-width: 768px) {
    display: block !important;
  }

  @media (min-width: 1280px) {
    display: none !important;
  }
}

.visibility_type_desktop-wide-only {
  display: none !important;

  @media (min-width: 1280px) {
    display: block !important;
  }
}

.visibility_type_flex-no-mobile {
  display: none !important;

  @media (min-width: 768px) {
    display: flex !important;
  }
}

.visibility_type_no-mobile {
  display: none !important;

  @media (min-width: 768px) {
    display: block !important;
  }
}

// доступный, но невидимый элемент
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
</style>
