<script>
import { RouterLink } from 'vue-router';

import { enableBodyScroll, disableBodyScroll } from '../../helpers/body-scroll';

import TopMenu from '../TopMenu/TopMenu';
import MobileMenu from '../MobileMenu/MobileMenu.vue';

export default {
  components: {
    MobileMenu,
    RouterLink,
    TopMenu,
  },

  data() {
    return {
      isMobileMenuOpen: false,
    }
  },

  methods: {
    toggleMobileMenu() {
      if (this.isMobileMenuOpen) {
        enableBodyScroll();
        this.isMobileMenuOpen = false;
      } else {
        disableBodyScroll({ savePosition: true });
        this.isMobileMenuOpen = true;
      }
    },
  }
}
</script>

<template>
  <header v-bem="{ name: 'header', defaultMods: { withOpenMobileMenu: isMobileMenuOpen }}">
    <div class="header__body">
      <div class="header__bg-gradient header__bg-gradient_type_1"></div>
      <div class="header__bg-gradient header__bg-gradient_type_2"></div>
      <div class="header__bg-gradient header__bg-gradient_type_3"></div>
      <div class="header__bg-gradient header__bg-gradient_type_4"></div>

      <RouterLink class="header__logo" to="/">porogov.net</RouterLink>

      <TopMenu
        :onMobileMenuToggle="toggleMobileMenu"
        :isMobileMenuOpen="isMobileMenuOpen"
      />
    </div>

    <MobileMenu v-if="isMobileMenuOpen"/>
  </header>
</template>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 10;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 36px;
  }
}

.header_with-open-mobile-menu {
  .header__body {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, .25);

    @media (min-width: 768px) {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .1);
    }
  }
}

.header__body {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  border-radius: 24px;
  background: linear-gradient(9.41deg, rgba(192, 192, 192, 0.2) -73.29%, rgba(0, 0, 0, 0) 26.24%), #FFFFFF;
  box-shadow: 0 0 18.335px -6.11168px rgba(0, 0, 0, 0.25);

  @media (min-width: 768px) {
    height: 54px;
    padding: 0 36px;
    box-shadow: 0 0 16.312px -5.43732px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 1024px) {
    height: 72px;
    padding: 0 48px;
    border-radius: 32px;
    box-shadow: 0 0 21.733px -7.24434px rgba(0, 0, 0, 0.25);
  }

  @media (min-width: 1280px) {
    height: 100px;
    padding: 0 64px;
    border-radius: 40px;
    box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.25);
  }
}

.header__bg-gradient {
  position: absolute;
  pointer-events: none;
}

.header__bg-gradient_type_1 {
  right: 0;
  width: 200px;
  height: 100px;
  transform: matrix(-0.28, 0.96, -0.79, -0.62, 0, 0);
  background: linear-gradient(123.04deg, #979CA3 13.58%, rgba(255, 255, 255, 0) 65.9%);
  opacity: 0.5;
  filter: blur(76px);

  @media (min-width: 768px) {
    top: -16px;
    right: 80px;
    width: 292px;
    height: 207px;
    filter: blur(67.9665px);
    transform: matrix(-0.42, 0.91, -0.91, -0.42, 0, 0);
  }

  @media (min-width: 1024px) {
    top: -80px;
    right: 100px;
    width: 389px;
    height: 276px;
    filter: blur(90.5543px);
  }

  @media (min-width: 1280px) {
    top: -120px;
    right: 150px;
    width: 540px;
    height: 381px;
    filter: blur(125px);
    transform: rotate(115deg);
  }
}

.header__bg-gradient_type_2 {
  top: -70px;
  left: -30px;
  width: 250px;
  height: 210px;
  transform: matrix(0.28, 0.96, 0.79, -0.62, 0, 0);
  background: linear-gradient(123deg, rgba(151, 156, 163, 0.05) 13.58%, rgba(255, 255, 255, 0) 66%);
  opacity: 0.8;
  filter: blur(76px);

  @media (min-width: 768px) {
    top: -150px;
    left: 30px;
    width: 360px;
    height: 424px;
    filter: blur(67.9665px);
    transform: matrix(0.42, 0.91, 0.91, -0.42, 0, 0);
  }

  @media (min-width: 1024px) {
    top: -200px;
    left: 80px;
    width: 480px;
    height: 564px;
    filter: blur(90.5543px);
  }

  @media (min-width: 1280px) {
    top: -250px;
    left: -30px;
    width: 666px;
    height: 780px;
    filter: blur(125px);
  }
}

.header__bg-gradient_type_3 {
  top: -30px;
  left: -60px;
  width: 100px;
  height: 120px;
  background: linear-gradient(123.04deg, #979CA3 13.58%, rgba(255, 255, 255, 0) 65.9%);
  filter: blur(30px);
  transform: matrix(0.97, 0.22, -0.23, 0.97, 0, 0);

  @media (min-width: 768px) {
    display: none;
  }
}

.header__bg-gradient_type_4 {
  width: 140px;
  height: 100px;
  top: -20px;
  right: -60px;
  background: linear-gradient(123.04deg, #979CA3 13.58%, rgba(255, 255, 255, 0) 65.9%);
  filter: blur(30px);
  transform: matrix(-0.42, 0.91, -0.91, -0.42, 0, 0);

  @media (min-width: 768px) {
    display: none;
  }
}

.header__logo {
  text-decoration: none;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #000;

  @media (min-width: 768px) {
    font-size: 19px;
    line-height: 21px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
    line-height: 28px;
  }

  @media (min-width: 1280px) {
    font-size: 36px;
    line-height: 40px;
  }
}
</style>
