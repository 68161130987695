<script>
import Section from '../Section/Section.vue';

export default {
  components: { Section }
}
</script>

<template>
  <Section>
    <div class="features-section">
      <div class="features-section__item features-section__item_type_steel">
        <img src="./assets/steel@mobile.jpg" alt="" class="features-section__item-img visibility_type_mobile-only">
        <img src="./assets/steel@tablet.jpg" alt="" class="features-section__item-img visibility_type_tablet-only">
        <img src="./assets/steel@desktop.jpg" alt="" class="features-section__item-img visibility_type_desktop-only">
        <img src="./assets/steel@desktop-wide.jpg" alt="" class="features-section__item-img visibility_type_desktop-wide-only">

        <h3 class="features-section__item-title">Сталь</h3>
        <div class="features-section__item-description">
          <p>Наша продукция<br/>производится из высококачественной марки стали 08Ю, предназначенной для производства сложных форм</p>
        </div>
      </div>
      <div class="features-section__item features-section__item_type_compatibility">
        <img src="./assets/compatibility@mobile.png" alt="" class="features-section__item-img visibility_type_mobile-only">
        <img src="./assets/compatibility@tablet.png" alt="" class="features-section__item-img visibility_type_tablet-only">
        <img src="./assets/compatibility@desktop.png" alt="" class="features-section__item-img visibility_type_desktop-only">
        <img src="./assets/compatibility@desktop-wide.png" alt="" class="features-section__item-img visibility_type_desktop-wide-only">

        <h3 class="features-section__item-title">99%<br/>совместимость</h3>
        <div class="features-section__item-description">
          <p>Все ремкоплекты изготавливаются по оригинальным меркам и чертежам, а так же проходят тестовую установку, что гарантирует полную совместимость с вашим авто</p>
        </div>
      </div>
      <div class="features-section__item features-section__item_type_delivery">
        <img src="./assets/delivery@mobile.png" alt="" class="features-section__item-img visibility_type_mobile-only">
        <img src="./assets/delivery@tablet.png" alt="" class="features-section__item-img visibility_type_tablet-only">
        <img src="./assets/delivery@desktop.png" alt="" class="features-section__item-img visibility_type_desktop-only">
        <img src="./assets/delivery@desktop-wide.png" alt="" class="features-section__item-img visibility_type_desktop-wide-only">

        <h3 class="features-section__item-title">Доставка</h3>
        <div class="features-section__item-description">
          <p>10 BYN по Минску,<br/>или <u>бесплатно</u> при сумме заказа от 150 BYN</p>
          <p>По Беларуси отправляем курьерскими службами, стоимость просчитывается индивидуально</p>
        </div>
      </div>
      <div class="features-section__item features-section__item_type_payment">
        <img src="./assets/payment@mobile.png" alt="" class="features-section__item-img visibility_type_mobile-only">
        <img src="./assets/payment@tablet.png" alt="" class="features-section__item-img visibility_type_tablet-only">
        <img src="./assets/payment@desktop.png" alt="" class="features-section__item-img visibility_type_desktop-only">
        <img src="./assets/payment@desktop-wide.png" alt="" class="features-section__item-img visibility_type_desktop-wide-only">

        <h3 class="features-section__item-title">Оплата</h3>
        <div class="features-section__item-description">
          <ul>
            <li>Онлайн</li>
            <li>Наличными</li>
            <li>Банковскими картами: Visa, Mastercard, МИР</li>
            <li>Безналичным платежом по счету</li>
          </ul>

          <img src="./assets/payment-types@mobile.jpg" alt="" class="visibility_type_mobile-only">
          <img src="./assets/payment-types@tablet.jpg" alt="" class="visibility_type_tablet-only">
          <img src="./assets/payment-types@desktop.jpg" alt="" class="visibility_type_desktop-only">
          <img src="./assets/payment-types@desktop-wide.jpg" alt="" class="visibility_type_desktop-wide-only">
        </div>
      </div>
    </div>
  </Section>
</template>

<style scoped lang="scss">
.features-section {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px;
  }

  @media (min-width: 1024px) {
    gap: 24px;
  }

  @media (min-width: 1280px) {
    gap: 32px;
  }
}

.features-section__item {
  position: relative;
  padding: 28px 32px;
  border-radius: 20px;
  background: #fff;
  // две тени в дизайне, штош :-)
  box-shadow: 0px 0px 14.8519px -4.95063px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 3.46544px 34.6544px rgba(0, 0, 0, 0.25));

  @media (min-width: 768px) {
    box-sizing: border-box;
    flex-grow: 1;
    width: calc(50% - 18px);
    padding: 48px 36px 36px;
    border-radius: 24px;
    box-shadow: 0px 0px 16.3803px -5.46008px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 3.82206px 38.2206px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1024px) {
    width: calc(50% - 24px);
    padding: 60px 48px 48px;
    border-radius: 32px;
    box-shadow: 0px 0px 21.824px -7.27467px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 5.09227px 50.9227px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1280px) {
    width: calc(50% - 32px);
    padding: 80px 64px 64px;
    border-radius: 40px;
    box-shadow: 0px 0px 30.101px -10.0337px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 7.02357px 70.2357px rgba(0, 0, 0, 0.25));
  }
}

.features-section__item-title {
  position: relative;
  font-size: 22px;
  line-height: 27px;
  font-weight: 800;
  text-transform: lowercase;

  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 1280px) {
    font-size: 46px;
    line-height: 58px;
  }
}

.features-section__item-description {
  position: relative;
  max-width: 300px;
  margin-top: 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;

  @media (min-width: 768px) {
    font-size: 11px;
    font-weight: 700;
    line-height: 28px;
  }

  @media (min-width: 1024px) {
    max-width: 360px;
    font-size: 15px;
    line-height: 36px;
  }

  @media (min-width: 1280px) {
    max-width: 480px;
    font-size: 22px;
    line-height: 54px;
  }

  p + p {
    margin-top: 12px;

    @media (min-width: 1280px) {
      margin-top: 16px;
    }
  }

  ul {
    list-style-type: disc;
  }

  li + li {
    margin-top: 12px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  img {
    display: block;
    margin: 0 -16px -24px;

    @media (min-width: 768px) {
      margin: 0 -8px -24px;
    }

    @media (min-width: 1024px) {
      margin: 0 -16px -36px;
    }

    @media (min-width: 1280px) {
      margin: 0 -16px -56px;
    }
  }
}

.features-section__item-img {
  position: absolute;
}

.features-section__item_type_steel {
  .features-section__item-img {
    top: 18px;
    right: 22px;
    width: 48%;
    max-width: 240px;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
}

.features-section__item_type_compatibility {
  // переопределяем, ломая mobile-first
  // всё для того, чтобы картинка сварщика не вылезала за края
  @media (max-width: 767px) {
    padding-bottom: calc((100vw - 375px) / 2 + 28px);
  }

  .features-section__item-img {
    top: -5%;
    right: 0;
    width: 35%;
  }
}

.features-section__item_type_delivery {
  .features-section__item-img {
    top: -2%;
    right: -2.5%;
    width: 49%;
    max-width: 240px;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
}

.features-section__item_type_payment {
  .features-section__item-img {
    top: -3%;
    right: -3.5%;
    width: 45%;
    max-width: 240px;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }
}
</style>
