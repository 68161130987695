<script>
const TEXTAREA_LINE_HEIGHT_PX = 22;

export default {
  props: {
    mix: String,
    results: [Array, null],
  },

  emits: ['input'],

  data() {
    return {
      query: '',
      rows: 1,
      isFocused: false,
      shouldShowResults: false,
    }
  },

  methods: {
    handleFocus() {
      this.isFocused = true;
    },

    handleBlur() {
      this.isFocused = false;
    },

    handleKeyDown(e) {
      if (e.key === 'Escape') {
        this.shouldShowResults = false;
      }
    }
  },

  watch: {
    query() {
      this.$emit('input', this.query);

      if (!this.query) {
        this.rows = 1;
        this.shouldShowResults = false;
        this.$refs.textarea.style.height = 'auto';
        return;
      }

      this.shouldShowResults = true;

      this.$refs.textarea.style.height = '0';

      this.$nextTick(() => {
        this.rows = Math.floor(this.$refs.textarea.scrollHeight / TEXTAREA_LINE_HEIGHT_PX);

        this.$nextTick(() => {
          this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
        });
      });
    },
  },
}
</script>

<template>
  <div v-bem="{ name: 'search-input', defaultMods: { focused: isFocused || (shouldShowResults && !!results), open: (shouldShowResults && !!results) }, mix }">
    <div class="search-input__phantom-body"></div>

    <div class="search-input__body">
      <textarea
        v-model="query"
        ref="textarea"
        class="search-input__field"
        placeholder="порог peugeot 2008"
        :rows="rows"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown="handleKeyDown"
      ></textarea>
      <div class="search-input__icon">
        <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="m17.574 16.344-4.139-4.429c-.233-.19 1.113-2.67 1.112-4.133C14.547 3.484 11.29 0 7.273 0 3.256 0 0 3.484 0 7.782c0 4.299 3.256 7.783 7.273 7.783 1.486.002 3.715-1.831 4.154-1.396l4.09 4.376c.135.145.295.26.471.337a1.37 1.37 0 0 0 1.114 0c.177-.078.337-.192.472-.337.273-.292.426-.688.426-1.1 0-.414-.153-.81-.426-1.101Zm-10.3-3.3c-2.716 0-4.917-2.356-4.917-5.261 0-2.906 2.201-5.261 4.916-5.261 2.715 0 4.917 2.355 4.917 5.26 0 2.906-2.202 5.261-4.917 5.261Z"/>
        </svg>
      </div>

      <!-- TODO: вероятно надо показывать какой-то индикатор загрузки во время подгрузки этих самых результатов -->
      <div v-if="shouldShowResults && !!results" class="search-input__results">
        <div v-if="results.length === 0" class="search-input__not-found">
          Ничего не нашлось по запросу :(
        </div>

        <RouterLink
          class="search-input__result-item"
          v-for="result in results"
          v-bind:key="result.url"
          v-html="result.label"
          :to="result.url"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-input {
  position: relative;
}

.search-input_focused {
  .search-input__body {
    z-index: 1;
    background: #fff;
    box-shadow: 0 4px 250px 200px rgba(255, 255, 255, 0.95);
  }

  .search-input__icon {
    color: #000;
  }
}

.search-input_open {
  .search-input__body {
    border-radius: 12px 12px 0 0;
    border-bottom: 0;

    @media (min-width: 1280px) {
      border-radius: 16px 16px 0 0;
    }
  }
}

.search-input__phantom-body {
  height: 60px;
}

.search-input__body {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  padding: 7px 0;
  border-radius: 12px;
  border: 2px solid #898989;

  @media (min-width: 1280px) {
    padding: 14px 0;
    border-radius: 16px;
  }
}

.search-input__field {
  width: 100%;
  border: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 22px;
  background: transparent;
  outline: none;
  resize: none;
  padding: 0 16px 0 44px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1280px) {
    font-size: 18px;
    padding: 0 36px 0 80px;
  }

  &::placeholder {
    color: #949393;
  }
}

.search-input__icon {
  position: absolute;
  left: 16px;
  top: 8px;
  user-select: none;
  color: #898989;

  @media (min-width: 1280px) {
    top: 16px;
    left: 36px;
    transform: scale(1.3);
  }
}

.search-input__results {
  position: absolute;
  top: 100%;
  left: -2px;
  right: -2px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 400px;
  padding: 0 16px;
  border-radius: 0 0 12px 12px;
  border: 2px solid #898989;
  border-top: 0;
  background: #fff;

  @media (min-width: 1280px) {
    max-height: 600px;
    padding: 0 36px;
    border-radius: 0 0 16px 16px;
  }
}

.search-input__not-found {
  padding: 16px 0;
  font-weight: 700;
  color: #898989;
}

.search-input__result-item {
  flex-shrink: 0;
  overflow: hidden;
  padding: 6px 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none;
  text-overflow: ellipsis;
  color: #898989;

  @media (min-width: 768px) {
    padding: 4px 0;
    font-size: 14px;
  }

  @media (min-width: 1280px) {
    padding: 10px 0;
    font-size: 18px;
    line-height: 24px;
  }

  &:hover {
    color: #000;
  }

  :global(b) {
    color: #000;
  }
}
</style>
