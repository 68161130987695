<script>
import Section from '../Section/Section.vue';
import Button from '../Button/Button.vue';

export default {
  components: {
    Button,
    Section
  }
}
</script>

<template>
  <Section>
    <div class="more-section">
      <img class="more-section__bg visibility_type_mobile-only" src="./assets/bg@mobile.png" alt="">
      <img class="more-section__bg visibility_type_tablet-only" src="./assets/bg@tablet.png" alt="">
      <img class="more-section__bg visibility_type_desktop-only" src="./assets/bg@desktop.png" alt="">
      <img class="more-section__bg visibility_type_desktop-wide-only" src="./assets/bg@desktop-wide.png" alt="">

      <div class="more-section__content">
        <h2 class="more-section__headline">Еще больше ремкомплектов в&nbsp;нашем каталоге</h2>

        <div class="more-section__actions">
          <Button
            mix="more-section__button"
            as="a"
            mod-type="secondary"
          >Перейти в каталог</Button>

          <Button
            mix="more-section__button"
            as="a"
            mod-type="secondary"
            href="#feedback"
          >Заказать</Button>
        </div>
      </div>
    </div>
  </Section>
</template>

<style scoped lang="scss">
.more-section {
  position: relative;
  padding: 16px 16px 10px;
  border-radius: 10px;
  background: #fff;
  filter: drop-shadow(0px 1.84964px 18.3502px rgba(0, 0, 0, 0.25));

  // добавляем отступы раньше обычного брейкпоинта, иначе блок совсем распердолит :-)
  @media (min-width: 560px) {
    padding: 24px;
  }

  @media (min-width: 768px) {
    // 7.8 — искусно эмпирически подобранный коэфф.
    padding: calc((100vw - 768px) / 7.8 + 24px) 24px;
    border-radius: 24px;
  }

  @media (min-width: 1024px) {
    padding: calc((100vw - 1024px) / 8 + 29px) 48px;
    border-radius: 32px;
  }

  @media (min-width: 1280px) {
    padding: calc((100vw - 1280px) / 8 + 36px) 60px calc((100vw - 1280px) / 8 + 50px);
    border-radius: 40px;
  }

  // у нас *контент* растёт до 1280px
  // с учётом отступов 1352px — последняя точка роста контента
  // докидываем дополнительно на скролл 20px
  @media (min-width: 1372px) {
    padding: 48px 60px 60px;
  }
}

.more-section__content {
  position: relative;
}

.more-section__headline {
  max-width: 270px;
  margin: 0 auto;
  font-size: 28px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  background: radial-gradient(122.52% 294.23% at -18.63% 50%, #000000 24.31%, rgba(0, 0, 0, 0.61) 51.91%, #000000 81.08%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 768px) {
    max-width: 480px;
    font-size: 27px;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    max-width: 580px;
    font-size: 36px;
  }

  @media (min-width: 1280px) {
    max-width: 820px;
    font-size: 50px;
  }
}

.more-section__bg {
  position: absolute;
  left: -5%;
  bottom: 0;
  right: -5%;
  width: 110%;
  max-width: none;

  @media (min-width: 768px) {
    left: -7%;
    right: -7%;
    width: 114%;
    filter: drop-shadow(0px 4.00413px 40.0413px rgba(0, 0, 0, 0.25));
  }

  @media (min-width: 1024px) {
    left: -3%;
    right: -3%;
    width: 106%;
  }
}

.more-section__actions {
  max-width: 280px;
  margin: 12px auto 0;
  display: flex;
  gap: 16px;
  justify-content: center;

  @media (min-width: 768px) {
    gap: 32px;
    max-width: 560px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
}

.more-section__button {
  flex-grow: 1;

  @media (min-width: 768px) {
    width: 280px;
    height: 56px;
    font-size: 18px;
    line-height: 50px;
    border-radius: 12px;
    border-width: 3px;
  }
}
</style>
